<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>诊单管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:'/XYOrderManage'}">寻医列表</el-breadcrumb-item>
            <el-breadcrumb-item>寻医详情</el-breadcrumb-item>
        </el-breadcrumb>
        <main class="el-main">
            <p style="margin-top: 0;text-align: left;">
                状态:
                <span v-if="State == 0">未提交</span>
                <span v-if="State == 1">待挂号</span>
                <span v-if="State == 2">已抓取</span>
                 <span v-if="State == 6">审核</span>
                <span v-if="State == 9">删除</span>
            </p>
            <table border="1" cellspacing="0" cellpadding="0" id="tblA" class="table-condensed">
                <tbody>
                    <tr>
                        <td colspan="10" align="center" class="td-title">客户信息</td>
                    </tr>
                    <tr>
                        <td width="120px" class="td-title">编号:</td>
                        <td width="200px" align="left">{{KID}}</td>
                        <td class="td-title">客户姓名:</td>
                        <td width="120px">{{custmsg.Cust_Name}}</td>
                        <td width="80px" class="td-title">性别:</td>
                        <td width="80px">{{custmsg.Cust_Sex == 0?'男':'女'}}</td>
                        <td class="td-title">年龄:</td>
                        <td width="100px">{{custmsg.Cust_Age}}</td>
                        <td class="td-title">联系方式:</td>
                        <td width="180px">{{custmsg.Tel1}}</td>
                    </tr>
                    <tr>
                        <td class="td-title">职业:</td>
                        <td>{{custmsg.Cust_Job}}</td>
                        <td class="td-title">出生日期:</td>
                        <td>{{Cust_Birthday}}</td>
                        <td class="td-title">身高:</td>
                        <td>{{custmsg.Cust_Height}}</td>
                        <td class="td-title">体重:</td>
                        <td>{{custmsg.Cust_Weight}}</td>
                        <td class="td-title">文化程度:</td>
                        <td>{{custmsg.Cust_Schools}}</td>
                    </tr>
                    <tr>
                        <td align="left" class="td-title">客户编号:</td>
                        <td align="left">{{custmsg.Cust_ID}}</td>
                        <td align="left" class="td-title">家庭住址:</td>
                        <td colspan="7">{{custmsg.Provice+custmsg.City+custmsg.Country+custmsg.Address}}</td>
                    </tr>
                    <tr>
                        <td class="td-title">主治医生:</td>
                        <td>{{custmsg.doc_name}}</td>
                        <td class="td-title">责任客服:</td>
                        <td>{{custmsg.Cust_Servicer}}</td>
                        <td class="td-title">诊单状态:</td>
                        <td colspan="2">
                            <template>
                                <span v-if="State == 0">未提交</span>
                                <span v-if="State == 1">待挂号</span>
                                <span v-if="State == 2">已抓取</span>
                                <span v-if="State == 6">审核</span>
                                <span v-if="State == 9">删除</span>
                            </template>
                        </td>
                        <td class="td-title">创建时间:</td>
                        <td colspan="3">{{custmsg.Create_Date}}</td>
                    </tr>
                    <tr>
                        <td colspan="10" align="center" class="td-title">客户症状体征</td>
                    </tr>
                    <tr>
                        <td class="td-title">症状和体征</td>
                        <td colspan="9">
                            <p v-for="(item,index) in list" :key="index">
                                {{item.KFiel_ShowCaption}}：{{item.KFValues}}<br></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
        </main>
        <el-row>
            <el-button @click="dialogFormVisible = true">修改状态</el-button>
        </el-row>
        <el-dialog title="修改状态" :visible.sync="dialogFormVisible">
            请选择要修改的状态：
            <el-select v-model="changestate" placeholder="请选择状态">
                <el-option v-for="item in options" :key="item.id" :label="item.value" :value="item.id">
                </el-option>
            </el-select>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false;changestate = ''">取 消</el-button>
                <el-button type="primary" @click="ModifyState">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        inject: ['reload'],
        data() {
            return {
                KID: '',
                list: '',
                Cust_ID: '',
                State: '',
                activeName: 'first',
                custmsg: {},
                Cust_Birthday: '',
                fa: {},
                photo: [],
                uid: '',
                dialogFormVisible: false,
                changestate: '',
                options: [
                    { id: 0, value: '未提交' },
                    { id: 1, value: '待挂号' },
                    { id: 6, value: '审核' },
                    { id: 2, value: '已抓取' },
                    { id: 9, value: '删除' },
                ],
                editdoc: false,
                search: '',
                docmsg: '',
                checked: true,
            }
        },
        mounted() {
            this.KID = this.$route.query.id;
            this.Cust_ID = sessionStorage.getItem('Cust_ID');
            this.uid = sessionStorage.getItem('id');
            this.getdetail();
            this.getcustdetail();
        },
        methods: {
            getdetail() {
                this.axios.get("/field/get_view_newgid?kid=" + this.KID)
                    .then((res) => {
                        if (res.data.code == 0) {
                            if(res.data.fiels.length>0){
                                this.list = res.data.fiels;
                            }else{
                                this.list = res.data.result;
                            }
                            this.State = res.data.dismain.State;
                            if (res.data.fa != '' && res.data.fa != null && res.data.fa != undefined) {
                                this.fa = res.data.fa;
                            }
                            if (res.data.photo != '' && res.data.photo != null && res.data.photo != undefined) {
                                this.photo = res.data.photo;
                            }
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            getcustdetail() {
                this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + this.Cust_ID)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.custmsg = res.data.result;
                            if(res.data.result.Cust_Birthday!=''&&res.data.result.Cust_Birthday!=null&&res.data.result.Cust_Birthday!=undefined){
                                this.Cust_Birthday = res.data.result.Cust_Birthday.split(' ')[0];
                            }else{
                                this.Cust_Birthday = '';
                            }
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            ModifyState() {
                // if (this.changestate == 3) {
                //     this.changestate = 0;
                // }
                this.axios.post("/field/update_z_newseadoc_main_state", this.qs.stringify({ state: this.changestate, kid: this.KID, uid: this.uid }))
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });
                            this.dialogFormVisible = false;
                            this.State = this.changestate;
                            sessionStorage.setItem('MedicalType', this.changestate);
                            this.reload();
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    }
</script>
<style>
    .el-main {
        display: block;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        overflow: auto;
        padding: 20px;
        width: 100%;
    }

    #tblA {
        width: 100%;
        margin: auto;
        text-align: left;
        border-spacing: 0;
        border-collapse: collapse;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 2px solid #000;
    }

    table {
        display: table;
        border-collapse: separate;
        white-space: normal;
        line-height: normal;
        font-weight: 400;
        font-size: medium;
        font-style: normal;
        color: -internal-quirk-inherit;
        text-align: start;
        border-spacing: 2px;
        border-color: grey;
        font-variant: normal;
    }

    #tblA td {
        display: table-cell;
        vertical-align: inherit;
        padding: 5px 2px;
    }

    #tblA td,
    #tblB td {
        padding: 5px;
    }

    .td-title {
        font-weight: 600;
    }

    .update-solution {
        margin: 30px auto;
        width: 100%;
    }

    .pic {
        width: 150px;
        height: 150px;
        float: left;
        margin-right: 20px;
        position: relative;
        font-size: 20px;
    }

    .pic i {
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 500;
        cursor: pointer;
    }

    .pic i:hover {
        color: #409EFF;
    }

    .pic a img {
        width: 100%;
        height: 100%;
    }
</style>